import React, { useContext, useEffect, useState } from "react";
import Context from "../Context";
import PrizeCard from "./PrizeCard";
import { Container, ListGroup, Form } from "react-bootstrap";

function PrizeList() {
  const [state, dispatch] = useContext(Context);
  const [childList, updateChildList] = useState([]);
  const [selectedChild, updateSelectedChild] = useState({});

  useEffect(() => {
    if(state.kids){
      updateChildListCall();
    }
  }, [state.kids]);

  function updateChildListCall(){
    fetch(state.urls.getChildrenApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let responseData = data;
        updateChildList(responseData.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      ))
        // dispatch({ type: "updateKids", payload: responseData });
      });
  }

  function updateSelected(e) {
    state.kids.forEach((kid) => {
      if (kid.name === e.target.value) {
        updateSelectedChild(kid);
      }
    });
  }

  function getPoints() {
    let points = 0;
    state.kids.forEach((kid) => {
      if (kid.name === selectedChild.name) {
        points = kid.points;
      }
    });
    return points;
  }

  return (
    <>
      {/* {console.log("selectedkid: ", selectedChild)} */}
      {/* {console.log("points: ", points)} */}
      {/* {console.log("kids: ", state.kids)} */}
      <Container >
      <h2 id="prize-page-header">Available Prizes</h2>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Form.Select value={selectedChild.name} onChange={updateSelected}>
            <option value="">Pick a Child</option>
            {childList
            .filter((kid)=>kid.name !== "All")
            .map((kid) => {
              return (
                <option key={`${kid.name}-${kid.points}-prize-page`} value={kid.name}>
                  {kid.name}
                </option>
              );
            })}
          </Form.Select>
        </ListGroup.Item>
      </ListGroup>
      <h2>{selectedChild.name} has Points Available: {getPoints()}</h2>
      </Container>
      <Container id="prize-container" fluid>
        {state.prizes
          .sort((a, b) => (a.name < b.name ? 1 : -1))
          .sort((a, b) => (a.price > b.price ? 1 : -1))
          .map((prize) => {
            return <PrizeCard key={`${prize.id}-prize-page`} prize={prize} selectedChild={selectedChild} currentPoints={getPoints()} />;
          })}
      </Container>
    </>
  );
}

export default PrizeList;
