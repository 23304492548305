import React, { useContext } from "react";
import {
  Row,
  Col,
  Card,
  Form,
} from "react-bootstrap";
import Context from "../Context";

function TaskForChild({ task, index, child }) {
  const [state, dispatch] = useContext(Context);

  function completeTask(e) {
    console.log("completed? ", e.target.checked)
    let updatedTask = {...task};
    updatedTask.complete = e.target.checked
    if(updatedTask.complete){
      updatedTask.whoFinished = updatedTask.assignedTo;
      addPoints(child.name);
    } else {
      updatedTask.whoFinished = ""
      editPoints();
    }
    updateTaskViaApi(updatedTask);
  }

  function editPoints() {
    let kidsCopy = [...state.kids];
    let name = state.taskList[index].whoFinished;
    console.log("edit points name: ", state.taskList[index]);
    kidsCopy.forEach((kid) => {
      if (kid.name === task.whoFinished) {
        kid.points = kid.points - parseInt(state.taskList[index].points);
        updatePoints(kid, "s", state.taskList[index].points);
      }
    });
  }


  function addPoints(name){
    let kidsCopy = [...state.kids];
    kidsCopy.forEach((kid) => {
      if (kid.name === name) {
        kid.points = kid.points + parseInt(state.taskList[index].points);
        updatePoints(kid, "a", state.taskList[index].points);
      }
    });
  }

  function updatePoints(kid, operator, points) {
    let newURL = `${state.urls.updateChildApi}?name=${kid.name}&operator=${operator}&points=${points}`;
    const viewOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(newURL, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newKidsList = newData;
        dispatch({ type: "updateKids", payload: newKidsList });
      });
  }

  function updateTaskViaApi(updatedTask){

    const viewOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedTask),
    };
    fetch(state.urls.taskApi, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newTaskList = newData;
        // console.log("newData: ", newTaskList);
        dispatch({ type: "updateTaskList", payload: newTaskList });
      });

  }


  

  return (
    <Row>
      {/* {console.log("kids list: ", state.kids)} */}
      <Col>
        <Card id="task-card" bg={task.complete ? "success" : "danger"}>
          <Card.Header>
            <div id="task-name-header">{task.taskName}</div>
          </Card.Header>
          <Form id="complete-checkbox-for-kids" >
            <Form.Check
              onChange={completeTask}
              type="checkbox"
              id="child-completed-task"
              label="Completed"
              checked={task.complete}
            />
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default TaskForChild;
