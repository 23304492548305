import React, { useState, useContext } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import Context from "../Context";

function AddPrize() {
  const [state, dispatch] = useContext(Context);

  let initialPrizeState = {
    name: "",
    price: 0,
    url: "",
  };

  const [prize, setPrize] = useState(initialPrizeState);

  function onChange(e) {
    console.log("e: ", e);
    setPrize({ ...prize, [e.target.name]: e.target.value });
  }

  function addPrize() {

    const viewOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prize),
      };
      fetch(state.urls.prizeApi, viewOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((newData) => {
          let newPrizeList = newData;
          console.log("newData: ", newPrizeList);
          dispatch({ type: "updatePrizeList", payload: newPrizeList });
          resetPrize()
        });
  }

  function resetPrize() {
    setPrize(initialPrizeState);
  }

  return (
    <Form id="add-a-prize-form">
      {/* {console.log("Prize: ", prize)} */}
      <Form.Group className="mb-3">
        <Form.Label>Prize Details:</Form.Label>
        <Form.Control
          name="name"
          value={prize.name}
          onChange={onChange}
          type="text"
          placeholder="i.e. Clean the downstairs bathroom"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Prize Price:</Form.Label>
        <Form.Control
          name="price"
          value={prize.price}
          onChange={onChange}
          type="number"
          min="0"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Prize Image URL:</Form.Label>
        <Form.Control
          name="url"
          value={prize.url}
          onChange={onChange}
          type="text"
        />
      </Form.Group>
      {prize.name !== "" && prize.price != 0 && prize.url !== "" ? (
        <Button variant="primary" onClick={addPrize}>
          Add Prize
        </Button>
      ) : (
        ""
      )}
      <Button variant="primary" onClick={resetPrize}>
        Reset
      </Button>
    </Form>
  );
}

export default AddPrize;
