import React from "react";
import { Badge } from "react-bootstrap";

function DateRangeBadges(incentives, readableDate) {
  return (
    <div>
          {incentives[0]?.dateRange.map((date) => {
            return (
              <Badge key={date.date.toString()} bg={date?.completed ? "success" : "danger"}>
                {readableDate(date.date)}
              </Badge>
            );
          })}
        </div>
  );
}

export default DateRangeBadges;
