import React, { useEffect } from "react";
import { useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import IncentiveAccordion from "./IncentiveAccordion";

let data = require("./INCENTIVES.json");

function IncentivePage() {

const [incentives, updateIncentives] = useState(data);

useEffect(() => {

},[incentives])

  return (
    <Container>
      <Accordion defaultActiveKey="0">
        {data?.map((incentive, index) => {
          return <IncentiveAccordion key={index} incentive={incentive} incentives={incentives} updateIncentives={updateIncentives} index={index}/>;
        })}

      </Accordion>
    </Container>
  );
}

export default IncentivePage;
