import React, { useEffect, useState } from "react";
import { Accordion, Badge } from "react-bootstrap";
import IncentiveCheckList from "./IncentiveCheckList";
import DateRangeBadges from "./DateRangeBadges";

function IncentiveAccordion({
  incentive,
  incentives,
  updateIncentives,
  index,
}) {
  const [datesArray, updateDatesArray] = useState();

  useEffect(() => {}, []);

  const getStartDate = () => {
    let startDateArray = incentive?.startDate.split("/");
    // console.log("date" + startDateArray);
    let year = startDateArray[2];
    let month = startDateArray[0];
    let day = startDateArray[1];
    let tempDate = new Date(year, month - 1, day);
    let newDate = tempDate.toString().split(" ");
    // console.log(newDate)
    return newDate[0] + " " + newDate[1] + " " + newDate[2];
  };

  const getEndDate = () => {
    let startDateArray = incentive?.endDate.split("/");
    // console.log("date" + startDateArray);
    let year = startDateArray[2];
    let month = startDateArray[0];
    let day = startDateArray[1];
    let tempDate = new Date(year, month - 1, day);
    let newDate = tempDate.toString().split(" ");
    // console.log(newDate)
    return newDate[0] + " " + newDate[1] + " " + newDate[2];
  };

  const getDateRange = () => {
    if (incentive) {
      let startDateArray = incentive?.startDate.split("/");
      // console.log("date" + startDateArray);
      let year = startDateArray[2];
      let month = startDateArray[0];
      let day = startDateArray[1];
      var tempStartDate = new Date(year, month - 1, day);
      // console.log(tempStartDate);
    }
    if (incentive) {
      let endDateArray = incentive?.endDate.split("/");
      // console.log("date" + endDateArray);
      let year = endDateArray[2];
      let month = endDateArray[0];
      let day = endDateArray[1];
      var tempEndDate = new Date(year, month - 1, day);
      // console.log(tempEndDate);
    }

    updateDatesArray(getDaysArray(tempStartDate, tempEndDate));
  };

  var getDaysArray = function (s, e) {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d));
    }
    return a;
  };

  const readableDate = (date) => {
    let startDateArray = date.split("T");
    // console.log("date: " + startDateArray);
    let dateArray = startDateArray[0].split("-");
    // console.log("date: " + dateArray)
    let year = dateArray[0];
    let month = dateArray[1];
    let day = dateArray[2];
    let tempDate = new Date(year, month - 1, day);
    let newDate = tempDate.toString().split(" ");
    // console.log(newDate);
    return newDate[0] + " " + newDate[1] + " " + newDate[2];
  };

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>{incentive?.name}</Accordion.Header>
      <Accordion.Body>
        <h5>Start Date: {getStartDate()}</h5>
        <h5>Completion Date: {getEndDate()}</h5>
        <h5>Details: {incentive?.description}</h5>
        <h5>Daily Tasks:</h5>
        <IncentiveCheckList
          dailyTasks={incentive?.dailyTasks}
          incentives={incentives}
          updateIncentives={updateIncentives}
          incentiveIndex={index}
        />
        <DateRangeBadges incentives={incentives} readableDate={readableDate} />
        <div>
          {incentives[0].dateRange?.map((date) => {
            return (
              <Badge key={date.date.toString()} bg={date?.completed ? "success" : "danger"}>
                {readableDate(date.date)}
              </Badge>
            );
          })}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default IncentiveAccordion;
