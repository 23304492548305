import { Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './Component/Home';
import ParentsPage from './Component/ParentsPage';
import PrizeList from './Component/PrizeList';
import IncentivePage from './Component/Incentives/IncentivePage';


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Home />}></Route>
                <Route path="/prizes" element={<PrizeList />}></Route>
                <Route path="/parents" element={<ParentsPage />}></Route>
                <Route path="/incentives" element={<IncentivePage />}></Route>
            </Route>
        </Routes>
    )
}