import "./App.css";

import { useEffect, useReducer } from "react";
import Context from "./Context";
import { Outlet } from "react-router-dom";
import Header from "./Component/Header";
let tasks = require('./TASKS.json');
let children = require('./CHILDREN.json');

let initialState = {
  today: "",
  taskList: tasks,
  kids: children,
  prizes: [],
  purchaseHistory: [],
  urls: {
    // Test URLS
    taskApi: "http://localhost:8888/api/tasks",
    completeTaskApi: "http://localhost:8888/api/task",
    getChildrenApi: "http://localhost:8888/api/children",
    updateChildApi: "http://localhost:8888/api/child",
    prizeApi: "http://localhost:8888/api/prizes",
    purchaseApi: "http://localhost:8888/api/purchase",
    // //
    // Production URL's
    // taskApi: "https://chorlist-backend.herokuapp.com/api/tasks",
    // completeTaskApi: "https://chorlist-backend.herokuapp.com/api/task",
    // getChildrenApi: "https://chorlist-backend.herokuapp.com/api/children",
    // updateChildApi: "https://chorlist-backend.herokuapp.com/api/child",
    // prizeApi: "https://chorlist-backend.herokuapp.com/api/prizes",
    // purchaseApi: "https://chorlist-backend.herokuapp.com/api/purchase",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updateTaskList":
      return { ...state, taskList: action.payload };
    case "updateKids":
      return { ...state, kids: action.payload };
    case "updatePrizeList":
      return { ...state, prizes: action.payload };
    case "updatePurchaseHistory":
      return { ...state, purchaseHistory: action.payload };
    case "initialize":
      return { ...state, taskList: action.payload };
    default:
      break;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   fetch(state.urls.taskApi)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       let responseData = data;
  //       // console.log("data from database: ", responseData)
  //       dispatch({ type: "updateTaskList", payload: responseData });
  //     });

  //   fetch(state.urls.getChildrenApi)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       let responseData = data;
  //       dispatch({ type: "updateKids", payload: responseData });
  //     });

  //   fetch(state.urls.prizeApi)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       let responseData = data;
  //       // console.log("prize data: ", responseData);
  //       dispatch({ type: "updatePrizeList", payload: responseData });
  //     });

  //     updateHistory()
  // }, []);

  function updateHistory(){
    fetch(state.urls.purchaseApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let responseData = data;
        // console.log("prize data: ", responseData);
        let newlist = responseData.sort((a, b) => (a.id < b.id ? 1 : -1)).slice(0, 10)
        dispatch({ type: "updatePurchaseHistory", payload: newlist });
      });
  }

  let initialTaskState = {
    id: 0,
    taskName: "",
    complete: false,
    whoFinished: "",
    points: 0,
    day: [],
  };

  let initialKidStats = {
    name: "",
    points: 0,
  };

  let initialPrizeState = {
    name: "",
    type: "",
    price: 0,
  };

  return (
    <Context.Provider value={[state, dispatch, updateHistory]}>
      <Header />
      <Outlet />
    </Context.Provider>
  );
}

export default App;
