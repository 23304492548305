import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import Context from "../Context";

function AddTask() {
  const [state, dispatch] = useContext(Context);

  let initialTaskState = {
    taskName: "",
    complete: false,
    whoFinished: "",
    assignedTo: "All",
    points: 0,
    day: [],
  };

  const [task, setTask] = useState(initialTaskState);

  function onChange(e) {
    setTask({ ...task, [e.target.name]: e.target.value });
  }

  function addTask() {
   
    const viewOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    };
    fetch(state.urls.taskApi, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newTaskList = newData;
        console.log("newData: ", newTaskList);
        dispatch({ type: "updateTaskList", payload: newTaskList });
        resetTask()
      });
  }

  function daysChange(e) {
    let copyOfDays = [...task.day];

    if (e.target.checked == true) {
      copyOfDays.push(e.target.name);
    } else {
      let i = copyOfDays.indexOf(e.target.name);
      if (i >= 0) {
        copyOfDays.splice(i, 1);
      }
    }
    setTask({ ...task, day: copyOfDays });
  }

  function resetTask() {
    setTask(initialTaskState);
  }

  return (
    <Form id="add-a-task-form">
        {console.log("task: ", task)}
      {/* <h2>Add A Chore</h2> */}
      <Form.Group className="mb-3">
        <Form.Label>Chore Details:</Form.Label>
        <Form.Control
          name="taskName"
          value={task.taskName}
          onChange={onChange}
          type="text"
          placeholder="i.e. Clean the downstairs bathroom"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Assigned To:</Form.Label>
        <Form.Select value={task.assignedTo} onChange={onChange} name="assignedTo">
          <option key="1-A" value="All">Everyone</option>
          <option key="2-B" value="James">James</option>
          <option key="3-C" value="Parker">Parker</option>
          <option key="4-D" value="Benji">Benji</option>
          <option key="5-E" value="Allie">Allie</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Chore Points</Form.Label>
        <Form.Select value={task.points} onChange={onChange} name="points">
          <option></option>
          <option key="1-A" value="1">1</option>
          <option key="2-B" value="2">2</option>
          <option key="3-C" value="3">3</option>
          <option key="4-D" value="4">4</option>
          <option key="5-E" value="5">5</option>
        </Form.Select>
      </Form.Group>
      <Form.Group >
        <Form.Label>Chore Day(s)</Form.Label>
        <div className="mb-3">
          {["Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ].map((day, index) => {
             return (task.day.indexOf(day) !== -1 ? 
                <Form.Check onChange={daysChange} key={`${day}-${index}-checked`} inline label={day} name={day} checked type="checkbox" />
               : 
                <Form.Check onChange={daysChange} key={`${day}-${index}`} inline label={day} name={day} type="checkbox" />)
              ;
            
          })}
        </div>
      </Form.Group>
      {task.taskName !== "" && task.points != 0 && task.day.length !== 0 ? (
        <Button variant="primary" onClick={addTask}>
          Add Task
        </Button>
      ) : (
        ""
      )}
      <Button variant="primary" onClick={resetTask}>
        Reset
      </Button>
    </Form>
  );
}

export default AddTask;
