import React, { useContext } from "react";
import {
  Row,
  Col,
  Card,
  ListGroup,
  Form,
  Button,
} from "react-bootstrap";
import Context from "../Context";

function Task({ task, index }) {
  const [state, dispatch] = useContext(Context);

  function completeTask(e) {
    let updatedTask = { ...task };
    // console.log("updating task: ", updatedTask);
    updatedTask.complete = true;
    updatedTask.whoFinished = e.target.value;

    const viewOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedTask),
    };
    fetch(state.urls.taskApi, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newTaskList = newData;
        // console.log("newData: ", newTaskList);
        dispatch({ type: "updateTaskList", payload: newTaskList });
      });
    addPoints(e.target.value);
  }

  function addPoints(name) {
    let kidsCopy = [...state.kids];
    kidsCopy.forEach((kid) => {
      if (kid.name === name) {
        kid.points = kid.points + parseInt(state.taskList[index].points);
        updatePoints(kid, "a", state.taskList[index].points);
      }
    });
  }

  function updatePoints(kid, operator, points) {
    let newURL = `${state.urls.updateChildApi}?name=${kid.name}&operator=${operator}&points=${points}`;
    const viewOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(newURL, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newKidsList = newData;
        // console.log("newData: ", newKidsList);
        dispatch({ type: "updateKids", payload: newKidsList });
      });
  }

  function editTask() {
    editPoints(index);
    let updatedTask = {...state.taskList[index]};
    updatedTask.complete = false;
    updatedTask.whoFinished = "";

    const viewOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedTask),
    };
    fetch(state.urls.taskApi, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newTaskList = newData;
        // console.log("newData: ", newTaskList);
        dispatch({ type: "updateTaskList", payload: newTaskList });
      });
  }

  function editPoints(index) {
    let kidsCopy = [...state.kids];
    let name = state.taskList[index].whoFinished;
    console.log("edit points name: ", state.taskList[index]);
    kidsCopy.forEach((kid) => {
      if (kid.name === name) {
        kid.points = kid.points - parseInt(state.taskList[index].points);
        updatePoints(kid, "s", state.taskList[index].points);
      }
    });
    // dispatch({ type: "updateKids", payload: kidsCopy });
  }

  return (
    <Row>
      {/* {console.log("kids list: ", state.kids)} */}
      <Col>
        <Card id="task-card" bg={task.complete ? "success" : "danger"}>
          <Card.Header>
            <div id="task-name-header">{task.taskName}</div>{" "}
            {/* {console.log(task.complete)} */}
            {task.complete ? (
              <Button id="edit-task" onClick={editTask}>
                edit
              </Button>
            ) : (
              ""
            )}
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              Completed By:{" "}
              {task.complete ? (
                task.whoFinished
              ) : (
                <Form.Select value={task.whoFinished} onChange={completeTask}>
                  <option value=""></option>
                  {state.kids
                  .filter((kid) => kid.name !== "All")
                  .map((kid) => {
                    return <option key={`${kid.name}-completed`}value={kid.name}>{kid.name}</option>
                  })}
                </Form.Select>
              )}
            </ListGroup.Item>
            <ListGroup.Item>Point Value: {task.points} </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
}

export default Task;
