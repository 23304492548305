import React, { useContext } from "react";
import Context from "../Context";
import { Card, Button } from "react-bootstrap";

function PrizeCard({ prize, selectedChild, currentPoints }) {
  const [state, dispatch] = useContext(Context);

  function purchasePrize(){
    let body = {
      who: selectedChild.name,
      prize: prize.name
    }
    const viewOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    fetch(state.urls.purchaseApi, viewOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((newData) => {
        let newKidsList = newData;
        // console.log("newData: ", newKidsList);
        dispatch({ type: "updateKids", payload: newKidsList });
      });
  }

  return (
    <Card id="prize-card" style={{ width: "18rem" }}>
      {/* {console.log("current points:", currentPoints)} */}
      <Card.Img id="prize-card-image" variant="top" src={prize.url} />
      <Card.Body>
        <Card.Title>{prize.name}</Card.Title>
        <Card.Text>
          <b>Price</b>: {prize.price} points
        </Card.Text>
        {prize.price <= currentPoints ? (
          <Button variant="primary" onClick={purchasePrize}>Buy</Button>
        ) : (
          <Button variant="secondary" diabled="true">
            Buy
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default PrizeCard;
