import React, {useContext, useEffect} from "react";
import { Accordion } from "react-bootstrap";
import Context from "../Context";
import AddPrize from "./AddPrize";
import AddTask from "./AddTask";
import PurchaseHistory from "./PurchaseHistory";

function ParentsPage() {
  const [state, dispatch, updateHistory] = useContext(Context);

  useEffect(() => {
    updateHistory();
  }, [state.kids])

  return (
    <>
      <h1 id="parents-page-heading">Parents Page</h1>
      <Accordion id="add-task-accordion">
        <Accordion.Header>Add a Chore</Accordion.Header>
        <Accordion.Body>
          <AddTask />
        </Accordion.Body>
      </Accordion>
      <Accordion id="add-task-accordion">
        <Accordion.Header>Add a Prize</Accordion.Header>
        <Accordion.Body>
          <AddPrize />
        </Accordion.Body>
      </Accordion>
      <Accordion id="add-task-accordion">
        <Accordion.Header>Purchase History</Accordion.Header>
        <Accordion.Body>
          <PurchaseHistory />
        </Accordion.Body>
      </Accordion>
    </>
  );
}

export default ParentsPage;
