import React, { useContext } from "react";
import Context from "../Context";
import { Card, ListGroup } from "react-bootstrap";

function KidsProgress() {
  const [state, dispatch] = useContext(Context);

  return (
    <Card id="kids-progress-card">
      <Card.Header>Kids Points</Card.Header>
      <ListGroup variant="flush">
        {state.kids
        .filter((kid) => kid.name !== "All" )
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .map((kid) => {
            return (
              <ListGroup.Item key={`${kid.name}-progress`}>
                {kid.name} - {kid.points} points
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </Card>
  );
}

export default KidsProgress;
