import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../Assets/chorlist-logo-flattened.png'

function Header() {
  return (
    <Nav defaultActiveKey="/home" as="ul">
      {/* <h1>Chorlist</h1> */}
      <img id="header-logo" src={Logo}></img>
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/">
          Home
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/prizes">
          Prize List
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/parents">
          Parents Page
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link as={Link} to="/incentives">
          Incentives
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default Header;
