import React, { useContext } from "react";
import { ListGroup } from "react-bootstrap";
import Context from "../Context";

function PurchaseHistory() {
  const [state, dispatch] = useContext(Context);

  return (
    <>
      <h3>Purchase History</h3>
      <ListGroup>
        {state.purchaseHistory.map((purchase) => {
          return (
            <ListGroup.Item key={`${purchase.id}-${purchase.who}-${purchase.prize}`}>
              {purchase.purchaseDate}  -  {purchase.who} bought {purchase.prize}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
}

export default PurchaseHistory;
