import React, { useContext, useState, useEffect } from "react";
import Task from "./Task";
import Context from "../Context";
import KidsProgress from "./KidsProgress";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import TaskByChild from "./TaskByChild";

function Home() {
  const [state, dispatch] = useContext(Context);
  const [todaysDay, setTodaysDay] = useState(getTodaysDay());
  const [key, setKey] = useState('home');

  function getTodaysDay() {
    let today = new Date();
    const options = { weekday: "long" };
    let todaysDay = new Intl.DateTimeFormat("en-US", options).format(today);
    return todaysDay;
  }

  return (
    <>
      {console.log(state.taskList)}
      {/* {console.log(todaysDay)} */}
      <h2 id="today-day-heading">{todaysDay}'s Tasks</h2>
      <Container fluid>
        <Row id="main-page-row">
          <Col id="kids-progress-column">
            <KidsProgress />
          </Col>
          <Col>
            {console.log("taskList: ", state.taskList)}
            {state.taskList.length >= 0 ? (
              <Tabs defaultActiveKey="James" id="assigned-chores-tabs" >
                {/* {console.log("state.kids: ", state.kids)} */}
                {state.kids
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map((kid) => {
                    return (
                      <Tab
                        key={`${kid.name}-task-tab`}
                        eventKey={kid.name}
                        title={kid.name}
                        id="home-page-child-tabs"
                      >
                        <TaskByChild
                          key={`${kid.name}-child-tasks`}
                          child={kid}
                        />
                      </Tab>
                    );
                  })}
              </Tabs>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
