import React from "react";
import { ListGroup } from "react-bootstrap";
import { AiOutlineCheckCircle, AiFillCheckCircle } from 'react-icons/ai';

function IncentiveCheckList({
  dailyTasks,
  incentives,
  updateIncentives,
  incentiveIndex,
}) {
  const changeCompletedStatus = (index) => {
    let incentivesCopy = { ...incentives };
    let completedStatus =
      incentivesCopy[incentiveIndex].dailyTasks[index].completed;
    incentivesCopy[incentiveIndex].dailyTasks[index].completed =
      !completedStatus;
    updateIncentives(incentivesCopy);
  };

  return (
    <ListGroup>
      {incentives[incentiveIndex].dailyTasks.map((task, index) => {
        return (
            <ListGroup.Item action
            key={index}
              variant={task.completed ? "success" : "danger"}
              onClick={() => {
                changeCompletedStatus(index);
              }}
            >
              {task.completed ? <AiFillCheckCircle /> : <AiOutlineCheckCircle />} {task.name}
            </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

export default IncentiveCheckList;
