import React, { useContext, useState } from "react";
import Context from "../Context";
import TaskForChild from "./TaskForChild";
import Task from './Task'

function TaskByChild({ child }) {
  const [state, dispatch] = useContext(Context);

  const [todaysDay, setTodaysDay] = useState(getTodaysDay());

  function getTodaysDay() {
    let today = new Date();
    const options = { weekday: "long" };
    let todaysDay = new Intl.DateTimeFormat("en-US", options).format(today);
    return todaysDay;
  }

  return (
    <>
      {state.taskList
      // .filter((task) => task.assignedTo === child.name)
      .filter((task) => task.assignedTo.includes(child.name))
      .map((task, index) => {
        return (
          child.name === "All" ? 
          <Task index={index} task={task}/>
          : 
          <TaskForChild
            key={`${task.name}-${task.index}`}
            task={task}
            index={index}
            child={child}
          />
        );
      })}
    </>
  );
}

export default TaskByChild;
